const initialState = {
  loading: false,
  totalSupply: 0,
  publicSaleActive: false,
  presaleActive: false,
  maxPetPurchasePerTx: 5,
  maxPresalePetPurchase: 3,
  petPricePublic: 50000000000000000,
  petPricePublicEth: 0.05,
  petPricePresale: 40000000000000000,
  petPricePresaleEth: 0.04,
  ownedPetTokenIds: [],
  presaleWLMint: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        publicSaleActive: action.payload.publicSaleActive,
        presaleActive: action.payload.presaleActive,
        // cost: action.payload.cost,
        maxPetPurchasePerTx: action.payload.maxPetPurchasePerTx,
        maxPresalePetPurchase: action.payload.maxPresalePetPurchase,
        petPricePublic: action.payload.petPricePublic,
        petPricePublicEth: action.payload.petPricePublicEth,
        petPricePresale: action.payload.petPricePresale,
        petPricePresaleEth: action.payload.petPricePresaleEth,
        ownedPetTokenIds: action.payload.ownedPets,
        presaleWLMint: action.payload.presaleWLMint,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
