import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

//import { NftGallery } from 'react-nft-gallery'
import { NftProvider, useNft } from "use-nft";
//let fetcher = ""; //["ethereum", { ethereum }];

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 250px;
  height: 80;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 20px;
  color: var(--primary-text);
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const NFTImageDisplay = styled.img`
  box-shadow: 0 10px 40px 10px rgb(0 0 0 / 18%);
  width: 300px;
  @media (min-width: 767px) {
    width: 512px;
  }
`;

export const StyledDisconnectButton = styled.button`
  padding: 15px;
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 16px;
  color: var(--primary-text);
  width: 140px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledOSLogo = styled.img`
  width: 20px;
  margin-right: 10px;
  vertical-align: text-top;
  @media (min-width: 767px) {
    width: 30px;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-size: 10px;
  @media (min-width: 767px) {
    font-size: 16px;
  }
`;

export const StyledLinkTitle = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-size: 20px;
  @media (min-width: 767px) {
    font-size: 24px;
  }
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 52px;
  color: #fff;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    BASE_API_URL: "",
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const launchTimeMachine = () => {
    window.open(CONFIG.TIME_MACHINE_URL);
  };

  const claimNFTs = () => {
    let cost = data.petPricePublic; //CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    //let totalGasLimit = String(gasLimit * mintAmount);

    setFeedback(`Minting...Please confirm transaction in your wallet`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mintPet(mintAmount)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);

        if (err.message.toLowerCase().includes("not enough funds")) {
          setFeedback("Error: Not enough funds");
        } else if (err.message.toLowerCase().includes("user denied")) {
          setFeedback("Error: User denied transaction");
        } else {
          setFeedback("Sorry, something went wrong. Please try again later.");
        }
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Successfully minted! Your Time Travel Pet Shop NFT is displayed below and can be viewed on OpenSea.io`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  async function fetchProof(wallet) {
    setFeedback(`Minting...Please confirm transaction in your wallet`);
    setClaimingNft(true);

    const response = await fetch(
      CONFIG.BASE_API_URL + "/merkle/proof/" + wallet
    );

    const proof = await response.json();
    //console.log(proof);

    if (proof == null || proof.length == 0) {
      setFeedback(
        `Not on the Allowlist - please verify you have selected the correct account in your wallet.`
      );
      setClaimingNft(false);
    } else {
      let cost = data.petPricePresale;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintAmount);
      //let totalGasLimit = String(gasLimit * mintAmount);

      blockchain.smartContract.methods
        .presaleMint(mintAmount, proof)
        .send({
          //   gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          if (err.message.toLowerCase().includes("not enough funds")) {
            setFeedback("Error: Not enough funds");
          } else if (err.message.toLowerCase().includes("user denied")) {
            setFeedback("Error: User denied transaction");
          } else {
            setFeedback("Sorry, something went wrong. Please try again later.");
          }
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Successfully minted! Your Time Travel Pet Shop NFT is displayed below and can be viewed on OpenSea.io`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }
  }

  const claimWL = () => {
    //call api to get the Proof for this address
    fetchProof(blockchain.account);
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let maxMint = 5;

    if (data.presaleActive) maxMint = data.maxPresalePetPurchase;

    if (data.publicSaleActive) maxMint = data.maxPetPurchasePerTx;

    if (newMintAmount > maxMint) {
      newMintAmount = maxMint;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  // useNft() is now ready to be used in your app. Pass
  // the NFT contract and token ID to fetch the metadata.
  function Nft(props) {
    const { loading, error, nft } = useNft(
      CONFIG.CONTRACT_ADDRESS,
      props.tokenId
    );

    // nft.loading is true during load.
    if (loading)
      return (
        <div style={{ color: "white" }}>
          Loading data for Time Travel Pet #{props.tokenId}…
        </div>
      );

    // nft.error is an Error instance in case of error.
    if (error || !nft) return <>Error.</>;

    let tokenLink = `${CONFIG.MARKETPLACE_TOKEN_LINK}${props.tokenId}`;

    // You can now display the NFT metadata.
    return (
      <div key={props.tokenId}>
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 50,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          <StyledLinkTitle
            target={"_blank"}
            href={tokenLink}
            title="View on OpenSea"
          >
            <StyledOSLogo
              alt={"Time Travel Pet Shop"}
              src={"/config/images/Logomark-White.png"}
            />
            {nft.name}
          </StyledLinkTitle>
        </s.TextTitle>

        <StyledLinkTitle target={"_blank"} href={nft.image} title="View image">
          <NFTImageDisplay src={nft.image} alt="Time Travel Pet Shop NFT" />
        </StyledLinkTitle>

        <s.SpacerSmall />
        <s.Container ai={"center"} jc={"center"} fd={"row"}>
          <NavBtnLink
            onClick={(e) => {
              e.preventDefault();
              launchTimeMachine();
            }}
          >
            TIME MACHINE
          </NavBtnLink>
        </s.Container>

        <s.SpacerSmall />
        <table className="blueTable">
          <thead>
            <tr>
              <th>Trait</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {nft.rawData.attributes.map((pet, index) => (
              <tr key={index}>
                <td>{pet.trait_type}</td>
                <td>{pet.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={
          CONFIG.SHOW_BACKGROUND ? "/config/images/abstract-purple.jpg" : null
        }
      >
        <StyledLogo alt={"logo"} src={"/config/images/TTPS_logo300x300.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"Time Travel Pet Shop"}
              src={"/config/images/ttps-rotate.gif"}
            />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {blockchain.account === "" || blockchain.smartContract === null ? (
              ""
            ) : (
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    marginRight: "20px",
                    color: "var(--accent-text)",
                  }}
                >
                  Connected to: {blockchain.account.substring(0, 5)}...
                  {blockchain.account.substring(blockchain.account.length - 4)}
                </s.TextDescription>
                {/* <StyledDisconnectButton
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    dispatch(disconnect())
                    e.preventDefault()
                  }}
                >
                  DISCONNECT
                </StyledDisconnectButton> */}
              </s.Container>
            )}

            <s.SpacerLarge />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 40,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply == 0 ? "-" : data.totalSupply}/
              {CONFIG.MAX_SUPPLY.toLocaleString("en-US")}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            ></s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME}{" "}
                      {CONFIG.NETWORK.ENVIRONMENT} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <NavBtnLink
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </NavBtnLink>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                        <s.SpacerXSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          We recommend using Metamask or Coinbase Wallet
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {data.publicSaleActive || data.presaleActive ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontSize: 30,
                            fontWeight: "bold",
                          }}
                        >
                          {blockchain.account === "" ||
                          blockchain.smartContract === null
                            ? CONFIG.DISPLAY_COST
                            : data.publicSaleActive
                            ? data.petPricePublicEth
                            : data.petPricePresaleEth}{" "}
                          {CONFIG.NETWORK.SYMBOL} / NFT
                        </s.TextTitle>
                        <s.SpacerXSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          (Excluding gas fees)
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Maximum{" "}
                          {blockchain.account === "" ||
                          blockchain.smartContract === null
                            ? CONFIG.MAX_PER_TX
                            : data.publicSaleActive
                            ? data.maxPetPurchasePerTx
                            : data.maxPresalePetPurchase}{" "}
                          NFTs {data.publicSaleActive ? "per transaction" : ""}
                        </s.TextDescription>
                        <s.SpacerXSmall />

                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledRoundButton
                            style={{ lineHeight: 0.4 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                          >
                            -
                          </StyledRoundButton>
                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                              fontSize: 30,
                              fontWeight: "bold",
                            }}
                          >
                            {mintAmount}
                          </s.TextDescription>
                          <s.SpacerMedium />
                          <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount();
                            }}
                          >
                            +
                          </StyledRoundButton>
                        </s.Container>
                        <s.SpacerSmall />
                        {data.presaleActive ? (
                          <>
                            {data.presaleWLMint != null &&
                            Number(data.presaleWLMint) >=
                              Number(data.maxPresalePetPurchase) ? (
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                You have minted your maximum NFTs for this
                                Presale. Please come back at Public Sale for
                                even more TTPS!
                              </s.TextDescription>
                            ) : (
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                Presale is Active - If you are on the Allowlist
                                (WL) you may Mint now.
                              </s.TextDescription>
                            )}

                            <s.SpacerXSmall />
                          </>
                        ) : (
                          ""
                        )}

                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          {claimingNft ? (
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            <>
                              {data.publicSaleActive ? (
                                <NavBtnLink
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTs();
                                    getData();
                                  }}
                                >
                                  {claimingNft ? "BUSY" : "MINT"}
                                </NavBtnLink>
                              ) : (
                                ""
                              )}
                              {data.presaleActive &&
                              (data.presaleWLMint == null ||
                                Number(data.presaleWLMint) <
                                  data.maxPresalePetPurchase) ? (
                                <>
                                  <s.SpacerXSmall />
                                  <NavBtnLink
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimWL();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "PRESALE MINT"}
                                  </NavBtnLink>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </s.Container>
                        <s.SpacerMedium />
                        {feedback != "" ? (
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              backgroundColor: "var(--primary)",
                              padding: "10px",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <s.TextTitle>
                        {data.loading
                          ? "Loading..."
                          : "No Current Sale - Please check back soon!"}
                      </s.TextTitle>
                    )}
                  </>
                )}
              </>
            )}

            <s.SpacerMedium />
            {data.publicSaleActive || data.presaleActive ? (
              <>
                <s.TextDescription
                  jc={"center"}
                  ai={"center"}
                  style={{
                    textAlign: "center",
                    color: "var(--primary)",
                    fontSize: 20,
                  }}
                ></s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  Smart Contract Address: {CONFIG.CONTRACT_ADDRESS}
                </StyledLink>
              </>
            ) : (
              ""
            )}
          </s.Container>

          <s.SpacerLarge />
          {/* <s.Container flex={1} jc={'center'} ai={'center'}>
            <StyledImg
              alt={'Time Travel Pet Shop'}
              src={'/config/images/TTPS-promo1.gif'}
              // style={{ transform: 'scaleX(-1)' }}
            />
          </s.Container> */}
        </ResponsiveWrapper>
        <s.SpacerMedium />

        {data.ownedPetTokenIds != null && data.ownedPetTokenIds.length > 0 ? (
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextSubTitle
              style={{
                textAlign: "center",
                color: "var(--accent-text)",
              }}
            >
              Owned Pets for Connected Wallet (
              {blockchain.account.substring(0, 5)}...
              {blockchain.account.substring(blockchain.account.length - 4)})
            </s.TextSubTitle>

            <NftProvider fetcher={["ethereum", { ethereum }]}>
              {data.ownedPetTokenIds.map((petId, index) => (
                <Nft tokenId={petId} key={index} />
              ))}
            </NftProvider>
            <s.SpacerSmall />
            <s.TextDescription>
              Image not displaying? It may take a minute. Reload/Re-Connect to
              the site, or you can view on OpenSea.
            </s.TextDescription>
          </s.Container>
        ) : (
          ""
        )}

        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          {/* <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription> */}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
