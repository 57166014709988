// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let publicSaleActive = await store
        .getState()
        .blockchain.smartContract.methods.saleIsActive()
        .call();

      let presaleActive = await store
        .getState()
        .blockchain.smartContract.methods.presaleIsActive()
        .call();

      let maxPetPurchasePerTx = await store
        .getState()
        .blockchain.smartContract.methods.maxPetPurchasePerTx()
        .call();

      let maxPresalePetPurchase = await store
        .getState()
        .blockchain.smartContract.methods.maxPresalePetPurchase()
        .call();

      let petPricePublic = await store
        .getState()
        .blockchain.smartContract.methods.petPricePublic()
        .call();

      let petPricePublicEth = await store
        .getState()
        .blockchain.web3.utils.fromWei(petPricePublic, "ether");

      let petPricePresale = await store
        .getState()
        .blockchain.smartContract.methods.petPricePresale()
        .call();

      let petPricePresaleEth = await store
        .getState()
        .blockchain.web3.utils.fromWei(petPricePresale, "ether");

      let ownedPets = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(
          store.getState().blockchain.account
        )
        .call();

      let presaleWLMint = await store
        .getState()
        .blockchain.smartContract.methods.wlAddressToMinted(
          store.getState().blockchain.account
        )
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          ownedPets,
          publicSaleActive,
          presaleActive,
          maxPetPurchasePerTx,
          maxPresalePetPurchase,
          petPricePublic,
          petPricePublicEth,
          petPricePresale,
          petPricePresaleEth,
          presaleWLMint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
